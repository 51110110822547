import Layout from '../../../containers/Layout'
import Title from '../../../components/Text/Title'
import Container from '../../../containers/Container'
import Paragraph from '../../../components/Text/Paragraph'

import { useState, useEffect } from 'react';
import endpoints from '../../../mixins/endpoints';
import api from '../../../mixins/api';
import apiBaseUrl from '../../../mixins/apiBaseUrl';
import DialogComp from '../../../components/Modals/Dialog'
import Button from "@mui/material/Button";
import ApplePass from '../../../models/ApplePass'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import THEME from '../../../styles/theme';
import { styled as styledComponent } from 'styled-components';
import { styled } from '@mui/material/styles'
import SendIcon from '@mui/icons-material/Send';

export default function DeletePass() {
    const [dataApplePass, setDataApplePass] = useState<ApplePass[]>([]);
    const [displayDialog, setDisplayDialog] = useState(false)
    const [dialogMessage, setDialogMessage] = useState("")
    const [displayDialogConfirm, setDisplayDialogConfirm] = useState(false)
    const [dialogConfirmMessage, setDialogConfirmMessage] = useState("")
    const [passId, setPassId] = useState("")
    const [passName, setPassName] = useState("")

    useEffect(() => {
      fetchApplePass();
    }, []);
  
    const fetchApplePass = async () => {
      api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllApplePassesEndpoint + '?&filter=IsArchived eq false').then((response =>{
          setDataApplePass(response.data)
      })).catch((error)=>{
          alert(error)
          console.log(error)
      })
    }

    function formatDisplayName(value: string){
      return value.split('.')[0]
    }

    const preFillCard = (event: SelectChangeEvent) => {
      let value = event.target.value as string
      
      setPassName(value)
      let item = dataApplePass.find((item)=> item.passName === value)
      if(item !== undefined){
        setPassId(item.id)    
      }
    }

    const handleClose = () => {
      setDisplayDialogConfirm(false);
      setDisplayDialog(false)
    };

    function checkInput(){
      if(passId !== ''){
        //disableApplePass()
        let name = passName.split('.')[0]
        setDialogConfirmMessage("Êtes-vous certain de vouloir désactiver la carte \"" + name + "\" ?")
        setDisplayDialogConfirm(true)
      }
      else{
        setDialogMessage("Veuillez sélectionner la carte virtuelle")
        setDisplayDialog(true)
      }
    }



    function disableApplePass(){
        api.methods.delete(apiBaseUrl.data().digitalFramework + endpoints.data().DisableApplePassEndpoint + "?id=" + passId).then(() =>{
            handleClose()
            setDialogMessage("La carte \"" + formatDisplayName(passName) + "\" vient d'être désactivée")
            setDisplayDialog(true)
            fetchApplePass()
        }).catch((error)=>{
            //alert(error)
            console.log(error)
            setDialogMessage("Une erreur est survenue : \"" + error)
            setDisplayDialog(true)
        })
    }

  return (
    <>
      <Layout
            active=''
            type="main"
            >
            <BlockContainer>
                <Container>
                    <Title type='emphasis' size='title' label='Apple Wallet' align='center' color="main" />
                    <Title type='general' size='subtitle' variant='section' label="Désactiver une carte" color="main" />
                    
                    <Paragraph type='general' size='paragraph' color="main">
                    La désactivation prend effet immédiatement sur tous les appareils qui possèdent la carte.
                    </Paragraph>
                    {/* Begin */}
                    <DialogComp type='main' display={displayDialog} setDisplay={setDisplayDialog} title='AGL - Carte virtuelle' text={dialogMessage}>customdialog</DialogComp>
                    <Dialog
                      open={displayDialogConfirm} 
                      onClose={handleClose}
                      PaperComponent={PaperStyle}>
                      <DialogTitle>Action irréversible</DialogTitle>
                      <DialogContent>
                        <ContentContainer >
                            {dialogConfirmMessage}
                        </ContentContainer>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={disableApplePass} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Oui
                        </Button>
                        <Button onClick={handleClose} 
                        sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                        ]}
                        autoFocus>
                          Non
                        </Button>
                      </DialogActions>
                    </Dialog> 
                    <center>
                    
                    <FormControl sx={{ m: 1,width:'28ch' }} variant="standard">
                        <InputLabel id="pass-label">Sélectionner une carte virtuelle</InputLabel>
                        <Select
                            labelId="pass-label"
                            id="pass-select"
                            value={passName}
                            label="Sélectionner une carte virtuelle"
                            onChange={preFillCard}
                        >
                            {dataApplePass.map((item, index = 0)=><MenuItem key={index++} value={item.passName}>{formatDisplayName(item.passName)}</MenuItem>)}
                        </Select>
                    </FormControl>
                    <br/><br/>
                    <Button variant="outlined" endIcon={<SendIcon />} onClick={checkInput} sx={{color:'#1B365F'}}>Désactiver</Button>
                    </center>
                    {/* End */}
                </Container>
            </BlockContainer>
        </Layout> 
    </>
  );
}

const BlockContainer = styledComponent.div`
  width: auto;
  position: relative;
  left: 20px;
  bottom: 50px;
`;


const PaperStyle = styled(Paper)`
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
`;

const ContentContainer = styledComponent.div`
  color: ${THEME.text.color.general};
`;
