export default{
    data:()=>({
        //QrCode
        BuildQrCodeEndpoint:"/v1/QrCode/BuildQrCode",
        BuildQrvCodeEndpoint:"/v1/QrCode/BuildQrvCode",
        GetQrCodeByIdEndpoint: "/v1/QrCode/GetQrCodeById",
        GetAllQrCodeEndpoint: "/v1/QrCode/GetAllQrCode",
        DeleteQrCodeEndpoint: "/v1/QrCode/DeleteQrCode",
        //Logo
        GetAllLogoEndpoint:"/v1/Logo/GetAllLogo",
        GetLogoByNameEndpoint:"/v1/Logo/GetLogoByName",
        AddNewLogoEndpoint:"/v1/Logo/NewLogo",
        UpdateLogoEndpoint:"/v1/Logo/UpdateLogo",
        DeleteLogoEndpoint:"/v1/Logo/DeleteLogo",
        //Google
        GetGoogleClassByIdEndpoint: "/v1/GoogleWallet/GetPassClassById",
        GetAllGoogleClassEndpoint: "/v1/GoogleWallet/GetAllPassClasses",
        GetGoogleObjectByIdEndpoint:"/v1/GoogleWallet/GetPassObjectById",
        GetAllGoogleObjectEndpoint:"/v1/GoogleWallet/GetAllPassObjects",
        GetGoogleObjectLinkEndpoint: "/v1/GoogleWallet/GetPassLink",
        NewGoogleClassEndpoint:"/v1/GoogleWallet/CreatePassClass",
        NewGoogleObjectEndpoint:"/v1/GoogleWallet/CreatePassObject",
        UpdateGoogleClassEndpoint:"/v1/GoogleWallet/UpdatePassClass",
        UpdateGoogleObjectEndpoint:"/v1/GoogleWallet/UpdatePassObject",
        DisableGoogleObjectEndpoint:"/v1/GoogleWallet/DisablePassObject",
        //Apple
        GetApplePassByIdEndpoint:"/v1/AppleWallet/GetPassById",
        GetAllApplePassesEndpoint:"/v1/AppleWallet/GetAllPasses",
        NewApplePassEndpoint:"/v1/AppleWallet/CreatePass",
        UpdateApplePassEndpoint:"/v1/AppleWallet/UpdatePass",
        DisableApplePassEndpoint:"/v1/AppleWallet/DisablePass",
        //ShortcutUrl
        GetShortUrlByIdEndpoint:"/v1/ShortUrl/GetById",
        GetShortUrlByNameEndpoint:"/v1/ShortUrl/GetByName",
        GetAllShortUrlsEndpoint:"/v1/ShortUrl/GetAll",
        GetRedirectUrlEndpoint:"/v1/ShortUrl/RedirectUrl",
        NewShortUrlEndpoint:"/v1/ShortUrl/New",
        UpdateShortUrlEndpoint:"/v1/ShortUrl/Update",
        DeleteShortUrlEndpoint:"/v1/ShortUrl/Delete",
    }),
    computed:{}
};