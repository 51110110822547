import React, { ReactNode } from 'react';
import { styled } from 'styled-components';
import THEME from '../../../styles/theme';

interface IParagraphProps {
    type?: string
    size?: string
    color?: string
    variant?: string
    children: ReactNode
    align?: string
    onClick?: (args: any) => void
}

interface IParagraphContainerProps {
  type?: string
  size?: string
  variant?: string
  align?: string
}

function Paragraph(props: IParagraphProps) {
  return (
    <ParagraphContainer 
      type={props.type}
      size={props.size}
      color={props.color} 
      variant={props.variant} 
      onClick={props.onClick}
      align={props.align}
    >
      {props.children}
    </ParagraphContainer>
  );
}

export default Paragraph;

const ParagraphContainer = styled.p<IParagraphContainerProps>`
    font-weight: ${
        props => props.type === 'main'? THEME.text.weight.main : 
        props => props.type === 'general'? THEME.text.weight.general : 
        props => props.type === 'emphasis'? THEME.text.weight.emphasis : THEME.text.weight.general};
    font-size: ${
        props => props.size === 'paragraph'? THEME.text.size.paragraph : 
        props => props.size === 'subparagraph'? THEME.text.size.subparagraph : THEME.text.size.paragraph};
    color: ${
        props => props.color === 'main'? THEME.text.color.main : 
        props => props.color === 'general'? THEME.text.color.general : 
        props => props.color === 'emphasis'? THEME.text.color.main : 
        props => props.color === 'primary'? THEME.text.color.primary : 
        props => props.color === 'principal'? THEME.text.color.principal : 
        props => props.color === 'secondary'? THEME.text.color.secondary : 
        props => props.color === 'terciary'? THEME.text.color.terciary : 
        props => props.color === 'diverse'? THEME.text.color.diverse : 
        THEME.text.color.general};
    margin: 5px 0;
    border-bottom: ${props => props.variant === 'section' && `1px solid ${THEME.border.color.diverse}`};
    text-decoration: ${props => props.variant === 'underline' && 'underline'};
    text-underline-offset: ${props => props.variant === 'underline' && '5px'};
    text-align: ${props => props.align? props.align : 'left'}
`;
