import { MsalProvider, AuthenticatedTemplate, useMsal, UnauthenticatedTemplate } from '@azure/msal-react'
import { Root } from './page/Root'
import { loginRequest } from './mixins/authConfig'
import Title from './components/Text/Title'
import Logo from './components/Illustration/Logo'
import Button from "@mui/material/Button"
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Paper from '@mui/material/Paper'
import THEME from './styles/theme'
import { styled } from 'styled-components'
import { jwtDecode } from "jwt-decode";
import { useState, useEffect } from 'react'

const MainContent = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()
  const [dialogMessage, setDialogMessage] = useState("")
  const [displayDialog, setDisplayDialog] = useState(false)
  const REFRESH_THRESHOLD = 300 // 300 - 5 minutes in seconds
  const TOKEN_CHECK_INTERVAL = 60000 // 1 minute in milliseconds
  const [counter, setCounter] = useState("")

  const handleRedirect = () => {
      instance
          .loginRedirect({
              ...loginRequest,
              prompt: 'create',
          })
          .catch((error) => console.log(error))
  }

  const handleLogoutRedirect = () => {
    instance.logoutRedirect().catch((error) => console.log(error))
  }

  
  const startSessionTimer = () =>{
    const activeSession = setInterval(() => {
        const token = sessionStorage.getItem("msal.idtoken")
        if (token) {
            const decodeToken = jwtDecode(token);
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            const timeUntilExpiry = decodeToken.exp - currentTime;
            if (timeUntilExpiry <= REFRESH_THRESHOLD) {     // Token is about to expire or has expired, refresh it
                setDialogMessage("Votre session va bientôt expirer")
                setDisplayDialog(true)
                startCounterAnswer()
            }
        }
    }, TOKEN_CHECK_INTERVAL)
    return () => clearInterval(activeSession)
  }

  const startCounterAnswer = () =>{
    let disconnectTrigger = 60
    const counterAnswer = setInterval(()=> {
        disconnectTrigger = disconnectTrigger - 1
        setCounter(disconnectTrigger)
        
        if(disconnectTrigger < 1){
            handleClose()
        }
    }, 1000)
    return () => clearInterval(counterAnswer)
  }

  const handleClose = () => {
        handleLogoutRedirect();
  };

  useEffect(() => {
    startSessionTimer()
  }, []);

  return (
    <>
        <Dialog
            open={displayDialog} 
            onClose={handleClose}
            PaperComponent={PaperStyle}>
            <DialogContent>
            <ContentContainer >
                {dialogMessage} : {counter >= 1 ? counter : 0}
            </ContentContainer>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleRedirect} 
                sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                    {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                ]}
                autoFocus>
                    Prolonger
                </Button>
                <Button onClick={handleClose} 
                sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
                    {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
                ]}
                autoFocus>
                    Se déconnecter
                </Button>
            </DialogActions>
        </Dialog> 
        <AuthenticatedTemplate>
            {activeAccount ? (
                <Root />
            ) : null}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <br/><br/>
            <center>
            <Logo type="main" />
            <Title type='emphasis' size='title' label='Digital Features' align='center' color='main' />
            <br/>
            <Button sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff', width: '110px', height: '45px'},
                {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
            ]} onClick={handleRedirect} autoFocus>Connexion</Button>
            </center>
        </UnauthenticatedTemplate>
    </>
  );
}

 /**
  * msal-react is built on the React context API and all parts of your app that require authentication must be 
  * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
  * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
  * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
  * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
  */
 const App = ({ instance }) => {
    
  return (
      <MsalProvider instance={instance}>
          <MainContent />
      </MsalProvider>
  );
}

export default App;

const PaperStyle = styled(Paper)`
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
`;

const ContentContainer = styled.div`
  color: ${THEME.text.color.general};
`;