import Layout from '../../../../containers/Layout'
import Title from '../../../../components/Text/Title'
import Container from '../../../../containers/Container'

import { useState, useEffect } from 'react'
import { IInputProps } from '../../../../components/Forms/InputV2'
import endpoints from '../../../../mixins/endpoints'
import api from '../../../../mixins/api'
import apiBaseUrl from '../../../../mixins/apiBaseUrl'
import Form from '../../../../components/Forms/FormV2'
import GoogleObject from '../../../../models/GoogleObject'
import Dialog from '../../../../components/Modals/Dialog'
import Checkbox from '../../../../components/Forms/Checkbox'
import styled from 'styled-components';
import moment from 'moment'

import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { useTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';


export default function UpdatePass() {
    const [dataGoogleObject, setDataGoogleObject] = useState<GoogleObject[]>([])
    const [displayDialog, setDisplayDialog] = useState(false)
    const [dialogCardMessage, setDialogCardMessage] = useState("")

    const [cardSelected, setCardSelected] = useState('')
    const [selectedNbRowsAvailable, setSelectedNbRowsAvailable] = useState(0)
    const [selectedNbRowsAvailableText, setSelectedNbRowsAvailableText] = useState('')
    const [selectedNbRows, setSelectedNbRows] = useState(0)
    
    const [typeOfUpdate, setTypeOfUpdate] = useState("")
    const [selectedTypeOfUpdate, setSelectedTypeOfUpdate] = useState("")
    const [usingWideLogo, setUsingWideLogo] = useState(false)
    const [hideInformationUsingWideLogo, setHideInformationUsingWideLogo] = useState(true)
    const [cardBarcodeTypeText, setCardBarcodeTypeText] = useState("")
    const [classId, setclassId] = useState("")
    const [objectId, setObjectId] = useState("")
    const [cardTitle, setCardTitle] = useState("")
    const [cardSubHeader, setCardSubHeader] = useState("")
    const [cardHeader, setCardHeader] = useState("")
    const [cardLogoUri, setCardLogoUri] = useState("")
    const [cardHeroImage, setCardHeroImage] = useState("")
    
    const [cardBarcodeType, setCardBarcodeType] = useState(-1)
    const [cardBarcodeValue, setCardBarcodeValue] = useState("")
    const [cardBarcodeAlternateValue, setCardBarcodeAlternateValue] = useState("")
    const [cardBackgroundColor, setCardBackgroundColor] = useState("")
    const [valididyCheckbox, setValidityCheckbox] = useState(false)
    const [cardValidityStart, setCardValidityStart] = useState(Date)
    const [cardValidityEnd, setCardValidityEnd] = useState(Date)

    const [row1CardItemLeftId, setRow1CardItemLeftId] = useState('')
    const [row1CardItemLeftTitle, setRow1CardItemLeftTitle] = useState('')
    const [row1CardItemLeftValue, setRow1CardItemLeftValue] = useState('')
    const [row1CardItemMiddleId, setRow1CardItemMiddleId] = useState('')
    const [row1CardItemMiddleTitle, setRow1CardItemMiddleTitle] = useState('')
    const [row1CardItemMiddleValue, setRow1CardItemMiddleValue] = useState('')
    const [row1CardItemRightId, setRow1CardItemRightId] = useState('')
    const [row1CardItemRightTitle, setRow1CardItemRightTitle] = useState('')
    const [row1CardItemRightValue, setRow1CardItemRightValue] = useState('')

    const [row2CardItemLeftId, setRow2CardItemLeftId] = useState('')
    const [row2CardItemLeftTitle, setRow2CardItemLeftTitle] = useState('')
    const [row2CardItemLeftValue, setRow2CardItemLeftValue] = useState('')
    const [row2CardItemMiddleId, setRow2CardItemMiddleId] = useState('')
    const [row2CardItemMiddleTitle, setRow2CardItemMiddleTitle] = useState('')
    const [row2CardItemMiddleValue, setRow2CardItemMiddleValue] = useState('')
    const [row2CardItemRightId, setRow2CardItemRightId] = useState('')
    const [row2CardItemRightTitle, setRow2CardItemRightTitle] = useState('')
    const [row2CardItemRightValue, setRow2CardItemRightValue] = useState('')

    const [row3CardItemLeftId, setRow3CardItemLeftId] = useState('')
    const [row3CardItemLeftTitle, setRow3CardItemLeftTitle] = useState('')
    const [row3CardItemLeftValue, setRow3CardItemLeftValue] = useState('')
    const [row3CardItemMiddleId, setRow3CardItemMiddleId] = useState('')
    const [row3CardItemMiddleTitle, setRow3CardItemMiddleTitle] = useState('')
    const [row3CardItemMiddleValue, setRow3CardItemMiddleValue] = useState('')
    const [row3CardItemRightId, setRow3CardItemRightId] = useState('')
    const [row3CardItemRightTitle, setRow3CardItemRightTitle] = useState('')
    const [row3CardItemRightValue, setRow3CardItemRightValue] = useState('')

    const [nfcEnabled, setNfcEnabled] = useState(false)
    const [redemptionValue, setRedemptionValue] = useState('')

    const dataListOneItem = ["0", "1"]
    const dataListTwoItems = ["0", "1", "2"]
    const dataListThreeItems = ["0", "1", "2", "3"]
    const dataListBarcode = [
        {
            key : 10,
            value : "Format QR"
        },
        {
            key:3,
            value: "Format Code128"
        },
        {
            key : 12,
            value : "Format Texte"
        }
    ]

    const dataListTypeOfUpdate = [{
        key : "0",
        value : "Mineure"
        },
        {
        key : "1",
        value : "Majeure"
        }
    ]
    
    const COLUMNLEFTBASE: IInputProps[] = 
    [
        {
            input:'text',
            type:'full',
            id:'cardTitle',
            label:'Titre de la carte (requis)',
            labelPosition: 'left',
            name:'cardTitle',
            placeholder:'Titre de la carte',
            value: cardTitle,
            setValue: setCardTitle
        },
        {
            input:'text',
            type:'full',
            id:'cardSubHeader',
            label:"Titre du sous en-tête",
            labelPosition: 'left',
            name:'cardSubHeader',
            placeholder:"Titre du sous en-tête",
            value: cardSubHeader,
            setValue: setCardSubHeader
        },
        {
            input:'text',
            type:'full',
            id:'cardHeader',
            label:"Titre de l'en-tête (requis)",
            labelPosition: 'left',
            name:'cardHeader',
            placeholder:"Titre de l'en-tête",
            value: cardHeader,
            setValue: setCardHeader
        }
    ]
    const COLUMNRIGHTBASE: IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'cardLogoUri',
            label:'URI du logo',
            labelPosition: 'left',
            name:'cardLogoUri',
            placeholder:"Si vide alors le logo AGL sera appliqué par défaut",
            value: cardLogoUri,
            setValue: setCardLogoUri
        },
        {
            input:'text',
            type:'full',
            id:'cardHeroImage',
            label:"URI de l'image principale",
            labelPosition: 'left',
            name:'cardHeroImage',
            placeholder:"URI de l'image principale",
            value: cardHeroImage,
            setValue: setCardHeroImage
        },
        {
            input:'text',
            type:'full',
            id:'cardBackgroundColor',
            label:"Couleur de la carte au format hexadecimal",
            labelPosition: 'left',
            name:'cardBackgroundColor',
            placeholder:"Si vide alors la couleur AGL sera appliquée par défaut",
            value: cardBackgroundColor,
            setValue: setCardBackgroundColor
        }
    ]
    const COLUMNLEFTBARCODE:IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'cardBarcodeValue',
            label:"Valeur du code barre",
            labelPosition: 'left',
            name:'cardBarcodeValue',
            placeholder:"Valeur du code barre",
            value: cardBarcodeValue,
            setValue: setCardBarcodeValue
        }
    ]
    const COLUMNRIGHTBARCODE:IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'cardBarcodeAlternateValue',
            label:"Valeur alternative du code barre",
            labelPosition: 'left',
            name:'cardBarcodeAlternateValue',
            placeholder:"Valeur alternative du code barre",
            value: cardBarcodeAlternateValue,
            setValue: setCardBarcodeAlternateValue
        }
    ]
    const COLUMNLEFTVALIDITY: IInputProps[] = 
    [
        {
            input:'date',
            type:'full',
            id:'cardValidityStart',
            label:'Date de validité',
            labelPosition: 'left',
            name:'cardValidityStart',
            //placeholder:'Date de validité',
            value: cardValidityStart,
            setValue: setCardValidityStart
        }
    ]
    const COLUMNRIGHTVALIDITY: IInputProps[] = 
    [
        {
            input:'date',
            type:'full',
            id:'cardValidityEnd',
            label:"Date d'expiration (requis)",
            labelPosition: 'left',
            name:'cardValidityEnd',
            //placeholder:"Date d'expiration",
            value: cardValidityEnd,
            setValue: setCardValidityEnd
        }
    ]
    const ROW1COLUMNLEFTTHREEITEMS : IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'row1CardItemLeftTitle',
            label:"Titre de l'élément gauche",
            labelPosition: 'left',
            name:'row1CardItemLeftTitle',
            placeholder:"Titre de l'élément gauche",
            value: row1CardItemLeftTitle,
            setValue: setRow1CardItemLeftTitle
        }
        ,
        {
            input:'text',
            type:'full',
            id:'row1CardItemMiddleTitle',
            label:"Titre de l'élément milieu",
            labelPosition: 'left',
            name:'row1CardItemMiddleTitle',
            placeholder:"Titre de l'élément milieu",
            value: row1CardItemMiddleTitle,
            setValue: setRow1CardItemMiddleTitle
        }
        ,
        {
            input:'text',
            type:'full',
            id:'row1CardItemRightTitle',
            label:"Titre de l'élément droit",
            labelPosition: 'left',
            name:'row1CardItemRightTitle',
            placeholder:"Titre de l'élément droit",
            value: row1CardItemRightTitle,
            setValue: setRow1CardItemRightTitle
        }
    ]
    const ROW1COLUMNRIGHTTHREEITEMS : IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'row1CardItemLeftValue',
            label:"Valeur de l'élément gauche",
            labelPosition: 'left',
            name:'row1CardItemLeftValue',
            placeholder:"Valeur de l'élément gauche",
            value: row1CardItemLeftValue,
            setValue: setRow1CardItemLeftValue
        },
        {
            input:'text',
            type:'full',
            id:'row1CardItemMiddleValue',
            label:"Valeur de l'élément milieu",
            labelPosition: 'left',
            name:'row1CardItemMiddleValue',
            placeholder:"Valeur de l'élément milieu",
            value: row1CardItemMiddleValue,
            setValue: setRow1CardItemMiddleValue
        },
        {
            input:'text',
            type:'full',
            id:'row1CardItemRightValue',
            label:"Valeur de l'élément droit",
            labelPosition: 'left',
            name:'row1CardItemRightValue',
            placeholder:"Valeur de l'élément droit",
            value: row1CardItemRightValue,
            setValue: setRow1CardItemRightValue
        },
    ]
    const ROW2COLUMNLEFTTHREEITEMS : IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'row2CardItemLeftTitle',
            label:"Titre de l'élément gauche",
            labelPosition: 'left',
            name:'row2CardItemLeftTitle',
            placeholder:"Titre de l'élément gauche",
            value: row2CardItemLeftTitle,
            setValue: setRow2CardItemLeftTitle
        }
        ,
        {
            input:'text',
            type:'full',
            id:'row2CardItemMiddleTitle',
            label:"Titre de l'élément milieu",
            labelPosition: 'left',
            name:'row2CardItemMiddleTitle',
            placeholder:"Titre de l'élément milieu",
            value: row2CardItemMiddleTitle,
            setValue: setRow2CardItemMiddleTitle
        }
        ,
        {
            input:'text',
            type:'full',
            id:'row2CardItemRightTitle',
            label:"Titre de l'élément droit",
            labelPosition: 'left',
            name:'row2CardItemRightTitle',
            placeholder:"Titre de l'élément droit",
            value: row2CardItemRightTitle,
            setValue: setRow2CardItemRightTitle
        }
    ]
    const ROW2COLUMNRIGHTTHREEITEMS : IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'row2CardItemLeftValue',
            label:"Valeur de l'élément gauche",
            labelPosition: 'left',
            name:'row2CardItemLeftValue',
            placeholder:"Valeur de l'élément gauche",
            value: row2CardItemLeftValue,
            setValue: setRow2CardItemLeftValue
        },
        {
            input:'text',
            type:'full',
            id:'row2CardItemMiddleValue',
            label:"Valeur de l'élément milieu",
            labelPosition: 'left',
            name:'row2CardItemMiddleValue',
            placeholder:"Valeur de l'élément milieu",
            value: row2CardItemMiddleValue,
            setValue: setRow2CardItemMiddleValue
        },
        {
            input:'text',
            type:'full',
            id:'row2CardItemRightValue',
            label:"Valeur de l'élément droit",
            labelPosition: 'left',
            name:'row2CardItemRightValue',
            placeholder:"Valeur de l'élément droit",
            value: row2CardItemRightValue,
            setValue: setRow2CardItemRightValue
        },
    ]
    const ROW3COLUMNLEFTTHREEITEMS : IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'row3CardItemLeftTitle',
            label:"Titre de l'élément gauche",
            labelPosition: 'left',
            name:'row3CardItemLeftTitle',
            placeholder:"Titre de l'élément gauche",
            value: row3CardItemLeftTitle,
            setValue: setRow3CardItemLeftTitle
        }
        ,
        {
            input:'text',
            type:'full',
            id:'row3CardItemMiddleTitle',
            label:"Titre de l'élément milieu",
            labelPosition: 'left',
            name:'row3CardItemMiddleTitle',
            placeholder:"Titre de l'élément milieu",
            value: row3CardItemMiddleTitle,
            setValue: setRow3CardItemMiddleTitle
        }
        ,
        {
            input:'text',
            type:'full',
            id:'row3CardItemRightTitle',
            label:"Titre de l'élément droit",
            labelPosition: 'left',
            name:'row3CardItemRightTitle',
            placeholder:"Titre de l'élément droit",
            value: row3CardItemRightTitle,
            setValue: setRow3CardItemRightTitle
        }
    ]
    const ROW3COLUMNRIGHTTHREEITEMS : IInputProps[] = [
        {
            input:'text',
            type:'full',
            id:'row3CardItemLeftValue',
            label:"Valeur de l'élément gauche",
            labelPosition: 'left',
            name:'row3CardItemLeftValue',
            placeholder:"Valeur de l'élément gauche",
            value: row3CardItemLeftValue,
            setValue: setRow3CardItemLeftValue
        },
        {
            input:'text',
            type:'full',
            id:'row3CardItemMiddleValue',
            label:"Valeur de l'élément milieu",
            labelPosition: 'left',
            name:'row3CardItemMiddleValue',
            placeholder:"Valeur de l'élément milieu",
            value: row3CardItemMiddleValue,
            setValue: setRow3CardItemMiddleValue
        },
        {
            input:'text',
            type:'full',
            id:'row3CardItemRightValue',
            label:"Valeur de l'élément droit",
            labelPosition: 'left',
            name:'row3CardItemRightValue',
            placeholder:"Valeur de l'élément droit",
            value: row3CardItemRightValue,
            setValue: setRow3CardItemRightValue
        },
    ]
    const COLUMNNFC: IInputProps[] = 
    [
        {
            input:'text',
            type:'full',
            id:'redemptionValue',
            label:'Code NFC',
            labelPosition: 'left',
            name:'redemptionValue',
            placeholder:'Valeur qui sera envoyée aux terminaux NFC comptatibles',
            value: redemptionValue,
            setValue: setRedemptionValue
        }
    ]

    const fetchGoogleClass = async (value : string) => {
        api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetGoogleClassByIdEndpoint + "?classId=" + value).then((response =>{
                initRowsFieldNameId(response.data.properties)
                setSelectedNbRowsAvailable(response.data.nbRows)
                setNfcEnabled(response.data.enableSmartTap || false)
        })).catch((error)=>{
            alert(error)
            console.log(error)
        })
    }

    const fetchGoogleObject = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetAllGoogleObjectEndpoint + '?&filter=State eq true').then((response =>{
            setDataGoogleObject(response.data)
        })).catch((error)=>{
            alert(error)
            console.log(error)
        })
    }

    useEffect(() => {
        fetchGoogleObject();
    }, []);

    const preFillCard = (event: SelectChangeEvent) => {
        let value = event.target.value as string
        setObjectId(value)
        setCardSelected(value)
        
        let item = dataGoogleObject.find((item)=> item.objectId === value)
        if(item !== undefined){
            fetchGoogleClass(item.classId)

            setclassId(item.classId)
            setCardLogoUri(item.logoUri)
            setCardHeroImage(item.heroImageUri)
            setCardBackgroundColor(item.backgroundColor ?? '')
            setCardTitle(item.title)
            setCardSubHeader(item.subHeader)
            setCardHeader(item.header)
            setCardBarcodeType(item.barcodeType)
            setCardBarcodeValue(item.barcodeValue)
            setCardBarcodeAlternateValue(item.barcodeValueAlternativeText)
            
            setUsingWideLogo(item.wideLogo)
            setHideInformationUsingWideLogo(!item.wideLogo)

            initProperties(item.properties)
            initSelectedNbItems(item.properties)
            initCardBarcodeTypeText(Number(item.barcodeType))
            initSelectedTypeOfUpdate('Mineure')
            initValidity(item.validityStart, item.validityEnd)
            setRedemptionValue(item.redemptionValue)
        }
        else {
            setCardBarcodeTypeText('')
            setSelectedTypeOfUpdate('')
            setUsingWideLogo(false)
            setHideInformationUsingWideLogo(true)
            initSelectedNbItems('')
            setValidityCheckbox(false)
            setCardValidityStart('')
            setCardValidityEnd('')
            setRedemptionValue('')
        }
    }

    function initRowsFieldNameId(value : string){
        if(value !== '' && value !== '[]'){
            const properties = JSON.parse(value)
            if(properties !== null && properties !== undefined && properties !== ''){
                switch(properties.length){
                    case 1 : 
                        setRow1CardItemLeftId(properties[0].ThreeItems?.FieldNameLeft?.Id)
                        setRow1CardItemMiddleId(properties[0].ThreeItems?.FieldNameMiddle?.Id)
                        setRow1CardItemRightId(properties[0].ThreeItems?.FieldNameRight?.Id)
                        break;
                    case 2 :
                        setRow1CardItemLeftId(properties[0].ThreeItems?.FieldNameLeft?.Id)
                        setRow1CardItemMiddleId(properties[0].ThreeItems?.FieldNameMiddle?.Id)
                        setRow1CardItemRightId(properties[0].ThreeItems?.FieldNameRight?.Id)

                        setRow2CardItemLeftId(properties[1].ThreeItems?.FieldNameLeft?.Id)
                        setRow2CardItemMiddleId(properties[1].ThreeItems?.FieldNameMiddle?.Id)
                        setRow2CardItemRightId(properties[1].ThreeItems?.FieldNameRight?.Id)
                    break;
                    case 3 : 
                        setRow1CardItemLeftId(properties[0].ThreeItems?.FieldNameLeft?.Id)
                        setRow1CardItemMiddleId(properties[0].ThreeItems?.FieldNameMiddle?.Id)
                        setRow1CardItemRightId(properties[0].ThreeItems?.FieldNameRight?.Id)

                        setRow2CardItemLeftId(properties[1].ThreeItems?.FieldNameLeft?.Id)
                        setRow2CardItemMiddleId(properties[1].ThreeItems?.FieldNameMiddle?.Id)
                        setRow2CardItemRightId(properties[1].ThreeItems?.FieldNameRight?.Id)

                        setRow3CardItemLeftId(properties[2].ThreeItems?.FieldNameLeft?.Id)
                        setRow3CardItemMiddleId(properties[2].ThreeItems?.FieldNameMiddle?.Id)
                        setRow3CardItemRightId(properties[2].ThreeItems?.FieldNameRight?.Id)
                    break;
                    default : break;
                }
            }
        }
    }

    function initProperties(value : string){
        if(value !== '' && value !== '[]'){
            const properties = JSON.parse(value)
            if(properties !== null && properties !== undefined && properties !== ''){
                switch(properties.length){
                    case 1 : 
                        setRow1CardItemLeftTitle(properties[0].ThreeItems?.FieldNameLeft?.Header)
                        setRow1CardItemLeftValue(properties[0].ThreeItems?.FieldNameLeft?.Body)
                        setRow1CardItemMiddleTitle(properties[0].ThreeItems?.FieldNameMiddle?.Header)
                        setRow1CardItemMiddleValue(properties[0].ThreeItems?.FieldNameMiddle?.Body)
                        setRow1CardItemRightTitle(properties[0].ThreeItems?.FieldNameRight?.Header)
                        setRow1CardItemRightValue(properties[0].ThreeItems?.FieldNameRight?.Body)
                        break;
                    case 2 :
                        setRow1CardItemLeftTitle(properties[0].ThreeItems?.FieldNameLeft?.Header)
                        setRow1CardItemLeftValue(properties[0].ThreeItems?.FieldNameLeft?.Body)
                        setRow1CardItemMiddleTitle(properties[0].ThreeItems?.FieldNameMiddle?.Header)
                        setRow1CardItemMiddleValue(properties[0].ThreeItems?.FieldNameMiddle?.Body)
                        setRow1CardItemRightTitle(properties[0].ThreeItems?.FieldNameRight?.Header)
                        setRow1CardItemRightValue(properties[0].ThreeItems?.FieldNameRight?.Body)

                        setRow2CardItemLeftTitle(properties[1].ThreeItems?.FieldNameLeft?.Header)
                        setRow2CardItemLeftValue(properties[1].ThreeItems?.FieldNameLeft?.Body)
                        setRow2CardItemMiddleTitle(properties[1].ThreeItems?.FieldNameMiddle?.Header)
                        setRow2CardItemMiddleValue(properties[1].ThreeItems?.FieldNameMiddle?.Body)
                        setRow2CardItemRightTitle(properties[1].ThreeItems?.FieldNameRight?.Header)
                        setRow2CardItemRightValue(properties[1].ThreeItems?.FieldNameRight?.Body)
                    break;
                    case 3 : 
                        setRow1CardItemLeftTitle(properties[0].ThreeItems?.FieldNameLeft?.Header)
                        setRow1CardItemLeftValue(properties[0].ThreeItems?.FieldNameLeft?.Body)
                        setRow1CardItemMiddleTitle(properties[0].ThreeItems?.FieldNameMiddle?.Header)
                        setRow1CardItemMiddleValue(properties[0].ThreeItems?.FieldNameMiddle?.Body)
                        setRow1CardItemRightTitle(properties[0].ThreeItems?.FieldNameRight?.Header)
                        setRow1CardItemRightValue(properties[0].ThreeItems?.FieldNameRight?.Body)

                        setRow2CardItemLeftTitle(properties[1].ThreeItems?.FieldNameLeft?.Header)
                        setRow2CardItemLeftValue(properties[1].ThreeItems?.FieldNameLeft?.Body)
                        setRow2CardItemMiddleTitle(properties[1].ThreeItems?.FieldNameMiddle?.Header)
                        setRow2CardItemMiddleValue(properties[1].ThreeItems?.FieldNameMiddle?.Body)
                        setRow2CardItemRightTitle(properties[1].ThreeItems?.FieldNameRight?.Header)
                        setRow2CardItemRightValue(properties[1].ThreeItems?.FieldNameRight?.Body)

                        setRow3CardItemLeftTitle(properties[2].ThreeItems?.FieldNameLeft?.Header)
                        setRow3CardItemLeftValue(properties[2].ThreeItems?.FieldNameLeft?.Body)
                        setRow3CardItemMiddleTitle(properties[2].ThreeItems?.FieldNameMiddle?.Header)
                        setRow3CardItemMiddleValue(properties[2].ThreeItems?.FieldNameMiddle?.Body)
                        setRow3CardItemRightTitle(properties[2].ThreeItems?.FieldNameRight?.Header)
                        setRow3CardItemRightValue(properties[2].ThreeItems?.FieldNameRight?.Body)
                    break;
                    default : break;
                }
            }
        }
    }

    function initValidity(start: string, end: string){
        if(start !== null || end !== null){
            setValidityCheckbox(true)
            if(start !== null && start !== ''){
                const startDate = moment(new Date(start)).format('YYYY-MM-DD');
                setCardValidityStart(startDate)
            }
            if(end !== null && end !== ''){
                const endDate = moment(new Date(end)).format('YYYY-MM-DD');
                setCardValidityEnd(endDate)
            }
        }else{
            setValidityCheckbox(false)
            setCardValidityStart('')
            setCardValidityEnd('')
        }
    }

    function initSelectedNbItems(value : string){
        let index = 0
        if(value !== '' && value !== '[]'){
            const properties = JSON.parse(value)
            if(properties !== null && properties !== undefined && properties !== ''){
                switch(properties.length){
                    case 1 : 
                        index++
                        break;
                    case 2 :
                        index = 2
                    break;
                    case 3 : 
                        index = 3 
                    break;
                    default : 
                    break;
                }

                setSelectedNbRowsAvailableText(index.toString())
                setSelectedNbRows(index)
            }
        }
    }

    function initCardBarcodeTypeText(value: number){
        setCardBarcodeTypeText(dataListBarcode.find((item)=> item.key === value)?.value || '')
    }

    function initSelectedTypeOfUpdate(value: string){
        setTypeOfUpdate(dataListTypeOfUpdate.find((item)=> item.value === value)?.key || '')
        setSelectedTypeOfUpdate(value)
    }

    const updateBarcodeType = (event: SelectChangeEvent) => {
        let value = event.target.value as string
        setCardBarcodeType(dataListBarcode.find((item)=> item.value === value)?.key || -1)
        setCardBarcodeTypeText(value)
    }

    const updateTypeOfUpdate = (event: SelectChangeEvent) => {
        let value = event.target.value as string
        setTypeOfUpdate(dataListTypeOfUpdate.find((item)=> item.value === value)?.key || '')
        setSelectedTypeOfUpdate(value)
    }

    function checkInputBeforeUpdate(){
        if(cardTitle === '' || cardBarcodeType === -1 || typeOfUpdate === '' || cardHeader === ''){
            setDialogCardMessage('Veuillez renseigner les champs obligatoires (requis)')
            setDisplayDialog(true)
        }
        else{
            UpdateGoogleObject()
        }
    }
    
    function updateWideLogoCheckbox(){
        if(!usingWideLogo){
            setUsingWideLogo(true)
            setHideInformationUsingWideLogo(false)
        }
        else{
            setUsingWideLogo(false)
            setHideInformationUsingWideLogo(true)
        }
    }

    function getDate(addMonth? : number){
        let date = new Date()
        let day = date.getDate()
        let month = date.getMonth() + 1
        let year = date.getFullYear()
      
        let dayStr = ''
        let monthStr = ''
        let extraMonths = addMonth ? addMonth : 0
      
        if(day < 10){
          dayStr = '0' + day
        }
        else{
          dayStr = day.toString()
        }
      
        if(month + extraMonths < 10)
        {
            monthStr = '0' + month
        }
        else{
            if(month + extraMonths > 12){
                let newMonth = month + extraMonths - 12
                if(newMonth < 10){
                    monthStr = '0' + newMonth
                }
                else{
                    monthStr = newMonth.toString()
                }
                year = year + 1
            }
            else{
                monthStr = month.toString()
            }
        }
      
        return year + '-' + monthStr + '-' + dayStr
    }

    function updateValidityCheckbox(){
        if(!valididyCheckbox){
            setValidityCheckbox(true)
            setCardValidityStart(getDate())
            setCardValidityEnd(getDate(6))
        }
        else{
            setValidityCheckbox(false)
            setCardValidityStart('')
            setCardValidityEnd('')
        }
    }

    const updateRowsDisplay = (event: SelectChangeEvent) => {
        let value = event.target.value as string
        let num = Number(value)

        setSelectedNbRowsAvailableText(value)
        setSelectedNbRows(num)
    }

    function formatNfcValue(){
        let result = ''
        if(nfcEnabled){
            result = 'Activé'
        }else{
            result = 'Désactivé'
        }

        return result
    }

    function UpdateGoogleObject(){
        let objectSend;
        let rowsArray
        //build object to send depend on nbItems

        switch(selectedNbRows){
            case 0:
                break;
            case 1:
                rowsArray = [
                    {
                        threeItems: {
                            fieldNameLeft : { id: row1CardItemLeftId, header: row1CardItemLeftTitle, body: row1CardItemLeftValue},
                            fieldNameMiddle : { id: row1CardItemMiddleId, header: row1CardItemMiddleTitle, body: row1CardItemMiddleValue},
                            fieldNameRight : { id: row1CardItemRightId, header: row1CardItemRightTitle, body: row1CardItemRightValue}
                        }
                    }
                ]
                break;
            case 2:
                rowsArray = [
                    {
                        threeItems: {
                            fieldNameLeft : { id: row1CardItemLeftId, header: row1CardItemLeftTitle, body: row1CardItemLeftValue},
                            fieldNameMiddle : { id: row1CardItemMiddleId, header: row1CardItemMiddleTitle, body: row1CardItemMiddleValue},
                            fieldNameRight : { id: row1CardItemRightId, header: row1CardItemRightTitle, body: row1CardItemRightValue}
                        }
                    },
                    {
                        threeItems: {
                            fieldNameLeft : { id: row2CardItemLeftId, header: row2CardItemLeftTitle, body: row2CardItemLeftValue},
                            fieldNameMiddle : { id: row2CardItemMiddleId, header: row2CardItemMiddleTitle, body: row2CardItemMiddleValue},
                            fieldNameRight : { id: row2CardItemRightId, header: row2CardItemRightTitle, body: row2CardItemRightValue}
                        }
                    },
                ]
                break;
            case 3:
                rowsArray = [
                    {
                        threeItems: {
                            fieldNameLeft : { id: row1CardItemLeftId, header: row1CardItemLeftTitle, body: row1CardItemLeftValue},
                            fieldNameMiddle : { id: row1CardItemMiddleId, header: row1CardItemMiddleTitle, body: row1CardItemMiddleValue},
                            fieldNameRight : { id: row1CardItemRightId, header: row1CardItemRightTitle, body: row1CardItemRightValue}
                        }
                    },
                    {
                        threeItems: {
                            fieldNameLeft : { id: row2CardItemLeftId, header: row2CardItemLeftTitle, body: row2CardItemLeftValue},
                            fieldNameMiddle : { id: row2CardItemMiddleId, header: row2CardItemMiddleTitle, body: row2CardItemMiddleValue},
                            fieldNameRight : { id: row2CardItemRightId, header: row2CardItemRightTitle, body: row2CardItemRightValue}
                        }
                    },
                    {
                        threeItems: {
                            fieldNameLeft : { id: row3CardItemLeftId, header: row3CardItemLeftTitle, body: row3CardItemLeftValue},
                            fieldNameMiddle : { id: row3CardItemMiddleId, header: row3CardItemMiddleTitle, body: row3CardItemMiddleValue},
                            fieldNameRight : { id: row3CardItemRightId, header: row3CardItemRightTitle, body: row3CardItemRightValue}
                        }
                    }
                ]
                break;
            default: 
                break;
        }

        objectSend = {
            classId: classId,
            objectId: objectId,
            wideLogo: usingWideLogo,
            logoUri: cardLogoUri,
            heroImageUri: cardHeroImage ?? '',
            backgroundColor: cardBackgroundColor ?? '',
            title: cardTitle ?? '',
            subHeader: cardSubHeader ?? '',
            header: cardHeader ?? '',
            barcodeType: cardBarcodeType ?? '', 
            barcodeValue: cardBarcodeValue ?? '',
            barcodeValueAlternativeText: cardBarcodeAlternateValue ?? '',
            rows: rowsArray != null && rowsArray.length > 0 ? rowsArray : null,
            redemptionValue : redemptionValue ?? '',
            validityStart: cardValidityStart !== '' ? cardValidityStart : null,
            validityEnd: cardValidityEnd !== '' ? cardValidityEnd : null
        }

        api.methods.put(apiBaseUrl.data().digitalFramework + endpoints.data().UpdateGoogleObjectEndpoint + "?update=" + typeOfUpdate, objectSend).then(() =>{
            setDialogCardMessage("La carte \"" + cardTitle + "\" vient d'être modifiée")
            fetchGoogleObject()
            setDisplayDialog(true)
          }).catch((error)=>{
              //alert(error)
              console.log(error)
              setDialogCardMessage("Une erreur est survenue : \"" + error)
              setDisplayDialog(true)
          })
    }
    return (
        <>
            <Layout
            active=''
            type="main"
            >
            <BlockContainer>
                <Container>
                    <Title type='emphasis' size='title' label='Google Wallet' align='center' color="main" />
                    <Title type='general' size='subtitle' variant='section' label="Modifier une carte" color="main" />
                    {/* Begin */}
                    <Dialog type='main' display={displayDialog} setDisplay={setDisplayDialog} title='AGL - Carte virtuelle' text={dialogCardMessage}>customdialog</Dialog>
                    <center>
                        <FormControl sx={{ m: 1,width:'25ch' }} variant="standard">
                            <InputLabel id="pass-label">ID de la carte virtuelle</InputLabel>
                            <Select
                                labelId="pass-label"
                                id="pass-select"
                                value={cardSelected}
                                label="ID de la carte virtuelle"
                                onChange={preFillCard}
                            >
                                {dataGoogleObject.map((item, index = 0)=><MenuItem key={index++} value={item.objectId}>{item.objectId}</MenuItem>)}
                            </Select>
                        </FormControl>
                        {cardSelected ? 
                            <>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <FormControl sx={{ m: 1,width:'20ch' }} variant="standard">
                                <InputLabel id="typeOfUpdate-label">Type de mise à jour</InputLabel>
                                <Select
                                    labelId="typeOfUpdate-label"
                                    id="typeOfUpdate-select"
                                    value={selectedTypeOfUpdate}
                                    label="Type de mise à jour"
                                    onChange={updateTypeOfUpdate}
                                >
                                    <MenuItem key={0} value={'Mineure'}>Mineure</MenuItem>
                                    <MenuItem key={1} value={'Majeure'}>Majeure</MenuItem>
                                </Select>
                            </FormControl>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <FormControl sx={{ m: 1,width:'20ch' }} variant="standard">
                                <InputLabel id="barcode-label">Type code barre</InputLabel>
                                <Select
                                    labelId="barcode-label"
                                    id="barcode-select"
                                    value={cardBarcodeTypeText}
                                    label="Type code barre"
                                    onChange={updateBarcodeType}
                                >
                                    <MenuItem key={10} value={'Format QR'}>Format QR</MenuItem>
                                    <MenuItem key={3} value={'Format Code128'}>Format Code128</MenuItem>
                                    <MenuItem key={12} value={'Format Texte'}>Format Texte</MenuItem>
                                </Select>
                            </FormControl>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <FormControl sx={{ m: 1,width:'10ch' }} variant="standard">
                                <InputLabel id="NbRows-label">Nb lignes</InputLabel>
                                <Select
                                    labelId="NbRows-label"
                                    id="NbRows-select"
                                    value={selectedNbRowsAvailableText}
                                    label="Nb lignes"
                                    onChange={updateRowsDisplay}
                                >
                                    {
                                        selectedNbRowsAvailable === 1 ? dataListOneItem.map((item, index = 0)=><MenuItem key={index++} value={item}>{item}</MenuItem>)
                                        : selectedNbRowsAvailable === 2 ? dataListTwoItems.map((item, index = 0)=><MenuItem key={index++} value={item}>{item}</MenuItem>)
                                        : selectedNbRowsAvailable === 3 ? dataListThreeItems.map((item, index = 0)=><MenuItem key={index++} value={item}>{item}</MenuItem>)
                                        : null
                                    }
                                </Select>
                            </FormControl>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <FormControl sx={{ m: 1 }} variant="standard">
                                <InputLabel htmlFor="demo-customized-textbox">Compatibilité NFC</InputLabel>
                                <BootstrapInput id="demo-customized-textbox" value={formatNfcValue()} disabled/>
                            </FormControl>
                            <br /><br />
                            <Checkbox label='Appliquer une période de validité ?' name='validity' value='' checked={valididyCheckbox} isChecked={updateValidityCheckbox}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Checkbox label='Utiliser un logo élargit' name='wideLogo' value='' checked={usingWideLogo} isChecked={updateWideLogoCheckbox}/>
                            <div hidden={hideInformationUsingWideLogo} id='WarningWideLogo'>
                                <Warning>
                                    <br />
                                    <b>Information:</b> L'utilisation d'un logo élargit <b>masque automatiquement</b> le titre de la carte
                                </Warning>
                            </div>
                            </> : null
                        } 
                    </center>
                    { nfcEnabled ?
                        <Form
                            title=""
                            first={COLUMNNFC}
                            type="main"
                            form="half" 
                            design="transparent"
                            corporate
                        >
                        </Form> : null
                    }
                    { valididyCheckbox ?
                        <Form
                            title="Validité"
                            titleSmallSize={true}
                            first={COLUMNLEFTVALIDITY}
                            second={COLUMNRIGHTVALIDITY}
                            type="main"
                            form="full" 
                            design="transparent"
                            corporate
                        >
                        </Form> : null
                    }
                    { 
                        objectId !== '' ?
                        <>
                        <Form
                            title=""
                            first={COLUMNLEFTBASE}
                            second={COLUMNRIGHTBASE}
                            type="main"
                            form="full" 
                            design="transparent"
                            corporate
                        />
                        <Form
                            title="Code barre"
                            titleSmallSize={true}
                            first={COLUMNLEFTBARCODE}
                            second={cardBarcodeType === -1 || cardBarcodeType === 12 ? [] : COLUMNRIGHTBARCODE}
                            type="main"
                            form="full" 
                            design="main"
                            corporate
                        />
                        </> : null
                    }
                    { selectedNbRows === 1 ?
                        <Form
                            title="Ligne 1"
                            titleSmallSize={true}
                            first={ROW1COLUMNLEFTTHREEITEMS}
                            second={ROW1COLUMNRIGHTTHREEITEMS}
                            type="main"
                            form="full" 
                            design="main"
                            corporate
                        >
                        </Form> : null
                    }
                    { selectedNbRows === 2 ?
                        <>
                        <Form
                            title="Ligne 1"
                            titleSmallSize={true}
                            first={ROW1COLUMNLEFTTHREEITEMS}
                            second={ROW1COLUMNRIGHTTHREEITEMS}
                            type="main"
                            form="full" 
                            design="main"
                            corporate
                        >
                        </Form>
                        <Form
                            title="Ligne 2"
                            titleSmallSize={true}
                            first={ROW2COLUMNLEFTTHREEITEMS}
                            second={ROW2COLUMNRIGHTTHREEITEMS}
                            type="main"
                            form="full" 
                            design="main"
                            corporate
                        >
                        </Form></> : null
                    }
                    { selectedNbRows === 3 ?
                        <>
                        <Form
                            title="Ligne 1"
                            titleSmallSize={true}
                            first={ROW1COLUMNLEFTTHREEITEMS}
                            second={ROW1COLUMNRIGHTTHREEITEMS}
                            type="main"
                            form="full" 
                            design="main"
                            corporate
                        >
                        </Form>
                        <Form
                            title="Ligne 2"
                            titleSmallSize={true}
                            first={ROW2COLUMNLEFTTHREEITEMS}
                            second={ROW2COLUMNRIGHTTHREEITEMS}
                            type="main"
                            form="full" 
                            design="main"
                            corporate
                        >
                        </Form>
                        <Form
                            title="Ligne 3"
                            titleSmallSize={true}
                            first={ROW3COLUMNLEFTTHREEITEMS}
                            second={ROW3COLUMNRIGHTTHREEITEMS}
                            type="main"
                            form="full" 
                            design="main"
                            corporate
                        >
                        </Form></> : null
                    }

                    { objectId !== '' ?
                        <center>
                            <Button variant="outlined" endIcon={<SendIcon />} onClick={checkInputBeforeUpdate} sx={{color:'#1B365F'}}>Modifier</Button>
                        </center> : null
                    }
                    {/* End */}
                </Container>
            </BlockContainer>
        </Layout> 
        </>
    );
}

const BlockContainer = styled.div`
  width: auto;
  position: relative;
  left: 20px;
  bottom: 50px;
`;

const Warning = styled.div`
  color: #c82121;
`;

const BootstrapInput = styled(InputBase)(() => ({
    'label + &': {
      marginTop: useTheme().spacing(2),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      textAlign: 'center',
      width: '70px',
      height:'10px',
      position: 'relative',
      backgroundColor: useTheme().palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: useTheme().transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));