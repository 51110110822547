import React, { ReactNode } from 'react';
import { styled } from 'styled-components';
import THEME from '../../styles/theme';

interface IContainerProps {
    type?: string
    design?: string
    children: ReactNode
}

function Container(props: IContainerProps) {
  return (
    <ContainerBloc type={props.type} design={props.design}>
        {props.children}
    </ContainerBloc>
  );
}

export default Container;

interface IContainerBlocProps {
  design?: string
  type?: string
}

const ContainerBloc = styled.div<IContainerBlocProps>`
  background-color: ${
    props => props.design === 'main' ? 
      THEME.background.color.main : 
    props.design === 'general' ? 
      THEME.background.color.general :
    props => props.design === 'primary' ? 
      THEME.background.color.primary :
    props => props.design === 'content' ? 
      THEME.background.color.content :
    props => props.design === 'transparent' ? 
      THEME.background.color.transparent :
    props => props.design === 'snow' ?
      THEME.background.color.main :  
      THEME.background.color.main
  };
  color: ${
    props => props.design === 'main' ? 
      THEME.text.color.main : 
    props.design === 'general' ? 
      THEME.text.color.general :
    props => props.design === 'primary' ? 
      THEME.text.color.main :
    props => props.design === 'content' ? 
      THEME.background.color.general :
    props => props.design === 'transparent' ? 
      THEME.background.color.general :
    props => props.design === 'snow' ?
      THEME.text.color.main :
      THEME.text.color.main
  };
  box-shadow: ${props => props.design === 'transparent' || props.design === 'snow' ? '': 'rgba(0, 0, 0, 0.08) 0 3px 10px 0'};
  width: ${props => props.type === "tiers" ? "30%" : props.type === "half" ? "50%" : props.type === "full" ? "80%" : "100%"};
  border-radius: 5px;
  padding: ${props => props.design === 'transparent' || props.design === 'snow' ? '0' : '30px'};
  margin: ${props => props.design === 'transparent' || props.design === 'snow' ? '0 auto' : '30px auto'};
`;
