import { styled } from 'styled-components'
import Layout from '../../containers/Layout'
import Title from '../../components/Text/Title'
import Container from '../../containers/Container'
import Paragraph from '../../components/Text/Paragraph';

function Homepage() {
    return (
        <>
        <Layout
            active=''
            type="main"
            >
            <BlockContainer>
                <Container>
                    <Title type='emphasis' size='title' label='Digital Features' align='center' color="main" />
                    <Title type='general' size='subtitle' variant='section' label='Web Forms' color="main" />
                    {/* Begin */}
                    <Paragraph type='general' size='paragraph' color="main">
                     Utiliser la barre de navigation gauche pour selectionner un service.
                     <br/><br/>
                     Pour plus de détails sur l'utilisation des services, vous trouverez leurs notices d'utilisation par le biais des liens suivants:
                    </Paragraph>
                    <ul>
                        <li><a href="https://dev.azure.com/agl-dev/digital-and-innovation/_wiki/wikis/digital-and-innovation.wiki/1331/Entit%C3%A9s" target="_blank" rel="noreferrer">Entités</a></li>
                        <li><a href="https://dev.azure.com/agl-dev/digital-and-innovation/_wiki/wikis/digital-and-innovation.wiki/1321/QRCode" target="_blank" rel="noreferrer">QRCode</a></li>
                        <li><a href="https://dev.azure.com/agl-dev/digital-and-innovation/_wiki/wikis/digital-and-innovation.wiki/1325/Raccourcisseur-d'URL" target="_blank" rel="noreferrer">Raccourcisseur d'URL</a></li>
                        <li><a href="https://dev.azure.com/agl-dev/digital-and-innovation/_wiki/wikis/digital-and-innovation.wiki/1324/Apple-Wallet" target="_blank" rel="noreferrer">Apple Wallet</a></li>
                     </ul>
                </Container>
            </BlockContainer>
        </Layout>    
    </>
    );
}


export default Homepage;

const BlockContainer = styled.div`
  width: auto;
  position: relative;
  left: 20px;
  bottom: 50px;
`;


