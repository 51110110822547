import Layout from '../../../containers/Layout'
import Title from '../../../components/Text/Title'
import Container from '../../../containers/Container'

import React, { useState, useEffect } from 'react'
import { styled as styledComponent } from 'styled-components'
import THEME from '../../../styles/theme'
import endpoints from '../../../mixins/endpoints'
import api from '../../../mixins/api'
import apiBaseUrl from '../../../mixins/apiBaseUrl'
import ApplePass from '../../../models/ApplePass'
import AppleWalletAddCard from '../../../assets/wallet/apple/frFR_add_to_apple_wallet_card.png'
import Button from "@mui/material/Button";
import Dialog from '../../../components/Modals/Dialog';
import LinkIcon from '@mui/icons-material/LinkRounded';
import { blue } from '@mui/material/colors'
import Paragraph from '../../../components/Text/Paragraph';
import { DataGrid,  
  GridColDef, 
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector } from '@mui/x-data-grid';
  import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
  import * as locales from '@mui/material/locale';

export default function DisplayPass() {
  const [dataApplePass, setDataApplePass] = useState<ApplePass[]>([]);
  const [dataApplePassArchived, setDataApplePassArchived] = useState<ApplePass[]>([]);
  const [displayDialog, setDisplayDialog] = useState(false)
  const [dialogMessage, setDialogMessage] = useState("")

  type SupportedLocales = keyof typeof locales;
  const [locale, setLocale] = React.useState<SupportedLocales>('frFR');
  const theme = useTheme();
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme],
  );

  useEffect(() => {
    fetchApplePass()
    fetchApplePassArchived()
  }, []);

  const fetchApplePass = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllApplePassesEndpoint + '?&filter=IsArchived eq false').then((response =>{
        setDataApplePass(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

  const fetchApplePassArchived = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllApplePassesEndpoint + '?&filter=IsArchived eq true').then((response =>{
        setDataApplePassArchived(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

  function styleDetail(style: number){
    let type = ''
    switch(style){
      case 0 :
        type = "Générique"
        break
      case 1 :
        type = "Carte d'embarquement"
        break
      case 2 :
        type = "Coupon"
        break
      case 3 :
        type = "Billet d'événement"
        break
      case 4 :
        type = "Carte de magasin" 
        break
      default:
        type = "Inconnu"
        break
    }
    
    return type
  }

  function NfcDisplay(value : boolean){
    var result = ''
    if(value){
        result = 'Activé'
    }
    else{
        result = 'Désactivé'
    }
    return result;
  }

  function openNewTab(id: string) {
    let obj = dataApplePass.find(x => x.id === id)
    if(obj !== undefined && obj !== null){
      if(navigator.platform === 'Win32'){
          setDialogMessage("L'ajout de la carte ne peut se faire qu'à partir d'un appareil provenant de la plateforme IOS")
          setDisplayDialog(true)
      }
      else{
          window.open(process.env.REACT_APP_BlobStorageUrl +"/apple-pkpass/"+ obj.storageId + '/' + obj.passName,"_blank");
      }
    }
  }

  function getPassLink(id: string){
    let obj = dataApplePass.find(x => x.id === id)
    if(obj !== undefined && obj !== null){
      navigator.clipboard.writeText(process.env.REACT_APP_BlobStorageUrl +"/apple-pkpass/"+ obj.storageId + '/' + obj.passName)
    }
  }

  function formatDisplayName(value: string){
    return value.split('.')[0]
  }

  function formatDate(parameter:string, hideHour : boolean){
    var result = ''
    if(parameter !== '')
    { 
      if(hideHour){
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'numeric', day: 'numeric' });
      }else{
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
      }
    }
    return result;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  const columnsTab1: GridColDef[] = [
    { field: 'passName', headerName: 'Nom', width: 180,
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDisplayName(param.value ? param.value : '')
    )},
    { field: 'style', headerName: 'Style', width: 180,
      renderCell: (param: GridRenderCellParams<any, number>)=>(
        styleDetail(param.value ? param.value : 0)
    )},
    { field: 'nfc', headerName: 'NFC', width: 100,
      renderCell: (param: GridRenderCellParams<any, boolean>)=>(
        NfcDisplay(param.value ? param.value : false)
    )},
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'created', headerName: 'Créé', width: 140,
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '' , false)
    )},
    { field: 'lastUpdate', headerName: 'Mise à jour', width: 140, 
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '', false)
    )},
    { field: 'relevantDate', headerName: 'Expire le', width: 100,  
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '', true)
    )},
    { field: 'version', headerName: 'Version', width: 80 },
    { field: 'id', headerName: 'Actions', width: 240, headerAlign: 'center', align:'left',
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        <>
        <Button onClick={(e) =>{openNewTab(param.value ? param.value : '')}}>
          <img src={AppleWalletAddCard} alt='' width={'125px'} />
        </Button>
        <Button onClick={(e) =>{getPassLink(param.value ? param.value : '')}}>
          <LinkIcon sx={{ fontSize: 40,color: blue[900] }} />
        </Button>
        </>
    )},
  ];

  const columnsTab2: GridColDef[] = [
    { field: 'passName', headerName: 'Nom', width: 180,
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDisplayName(param.value ? param.value : '')
    )},
    { field: 'style', headerName: 'Style', width: 180,
      renderCell: (param: GridRenderCellParams<any, number>)=>(
        styleDetail(param.value ? param.value : 0)
    )},
    { field: 'nfc', headerName: 'NFC', width: 100,
      renderCell: (param: GridRenderCellParams<any, boolean>)=>(
        NfcDisplay(param.value ? param.value : false)
    )},
    
    { field: 'created', headerName: 'Créé', width: 140,
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '' , false)
    )},
    { field: 'lastUpdate', headerName: 'Mise à jour', width: 140, 
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '', false)
    )},
    { field: 'lastUpdateBy', headerName: 'Mise à jour par', width: 180 },
    { field: 'relevantDate', headerName: 'Expire le', width: 100,  
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '', true)
    )},
    { field: 'version', headerName: 'Version', width: 100 },
  ];

  return (
    <>
      <Layout
            active=''
            type="main"
            >
            <BlockContainer>
                <Container>
                    <Title type='emphasis' size='title' label='Apple Wallet' align='center' color="main" />
                    <Title type='general' size='subtitle' variant='section' label='Cartes disponibles' color="main" />
                    {/* Begin */}
                    <Dialog type='main' display={displayDialog} setDisplay={setDisplayDialog} title='AGL - Apple Wallet' text={dialogMessage}>customdialog</Dialog>
                    <center>CARTES ACTIVES</center>
                    <Paragraph type='general' size='subparagraph' color="main">            
                      <LinkIcon sx={{ fontSize: 20,color: blue[900] }}/> Copier le lien
                    </Paragraph>
                    <Align>
                        <ThemeProvider theme={themeWithLocale}>
                          <DataGrid columns={columnsTab1} 
                                    rows={dataApplePass} 
                                    rowSelection={false} 
                                    pageSizeOptions={[10,25,100]}
                                    initialState={{
                                      pagination: { 
                                        paginationModel: { pageSize: 10 },
                                      },
                                    }}
                                    slots={{ toolbar: CustomToolbar }}
                            />
                        </ThemeProvider>
                    </Align>
                    <br /><br />
                    <center>CARTES INACTIVES<br/>
                    <i>Note : Durée de rétention de 30 jours glissants avant suppression définitive</i>
                    </center>
                    <Align>
                        <ThemeProvider theme={themeWithLocale}>
                          <DataGrid columns={columnsTab2} 
                                    rows={dataApplePassArchived} 
                                    rowSelection={false} 
                                    pageSizeOptions={[10,25,100]}
                                    initialState={{
                                      pagination: { 
                                        paginationModel: { pageSize: 10 },
                                      },
                                    }}
                                    slots={{ toolbar: CustomToolbar }}
                            />
                        </ThemeProvider>
                    </Align>
                    {/* End */}
                </Container>
            </BlockContainer>
        </Layout> 
    </>
  );
}

const BlockContainer = styledComponent.div`
  width: auto;
  position: relative;
  left: 20px;
  bottom: 50px;
`;

const Align = styledComponent.div`
p {
  margin-top: 13px
}
.MuiDataGrid-columnHeader{
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
}
.MuiButtonBase-root{
  color: ${THEME.background.color.general};
}
`;