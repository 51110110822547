import Layout from '../../../../containers/Layout'
import Title from '../../../../components/Text/Title'
import Container from '../../../../containers/Container'

import React, { useState, useEffect } from 'react'
import { styled as styledComponent } from 'styled-components'
import endpoints from '../../../../mixins/endpoints'
import api from '../../../../mixins/api'
import apiBaseUrl from '../../../../mixins/apiBaseUrl'
import GoogleClass from '../../../../models/GoogleClass'
import Issuer from '../../../../models/Issuer'
import THEME from '../../../../styles/theme'
import { DataGrid,  
  GridColDef, 
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector } from '@mui/x-data-grid';
  import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
  import * as locales from '@mui/material/locale';

export default function DisplayPass() {
  const [dataGoogleClass, setDataGoogleClass] = useState<GoogleClass[]>([]);

  type SupportedLocales = keyof typeof locales;
    const [locale, setLocale] = React.useState<SupportedLocales>('frFR');
    const theme = useTheme();
    const themeWithLocale = React.useMemo(
      () => createTheme(theme, locales[locale]),
      [locale, theme],
  );

  useEffect(() => {
    fetchGoogleClass();
  }, []);

  const fetchGoogleClass = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllGoogleClassEndpoint).then((response =>{
      setDataGoogleClass(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

  function formatIssuersValues(value: string){
    var result= ''
    const properties:Issuer[] = JSON.parse(value)
    if(properties !== null && properties !== undefined && properties.length >= 1){
        properties.forEach((element:Issuer, index, properties) => {
          if(properties.length - 1 === index){
            result += element.Id
          }
          else{
            result += element.Id + ';'
          }
      });
    }

    return result
  }

  function styleDetail(style: number){
    if(style === 0){
      return "Générique"
    }
    else{
      return "Inconnu"
    }
  }

  function smartTapDisplay(value : boolean){
    var result = ''
    if(value){
        result = 'Activé'
    }
    else{
        result = 'Désactivé'
    }
    return result;
  }

  function getTypeOfStyle(style: number){
    let result = ''
    switch(style){
        case 0: result = '_generic_'
                break;
        default: result = '_generic_'
                break;
    }

    return result
}

function formatDate(parameter:string, hideHour : boolean){
  var result = ''
  if(parameter !== '')
  { 
    if(hideHour){
      result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'numeric', day: 'numeric' });
    }else{
      result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
    }
  }
  return result;
}

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }
  
  const columns: GridColDef[] = [
    { field: 'classId', headerName: 'Id', width: 200 },
    { field: 'style', headerName: 'Style', width: 100,
      renderCell: (param: GridRenderCellParams<any, number>)=>(
        styleDetail(param.value ? param.value : 0)
    )},
    { field: 'nbRows', headerName: 'Nb lignes', width: 100 },
    { field: 'enableSmartTap', headerName: 'Smart Tap (NFC)', width: 130,
      renderCell: (param: GridRenderCellParams<any, boolean>)=>(
        smartTapDisplay(param.value ? param.value : false)
    )},
    { field: 'redemptionIssuers', headerName: 'Id Marchand(s)', width: 200,
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatIssuersValues(param.value ? param.value : '')
    )},
    { field: 'created', headerName: 'Créé', width: 140,
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '' , false)
    )},
    { field: 'lastUpdate', headerName: 'Mise à jour', width: 140, 
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '', false)
    )},
    { field: 'version', headerName: 'Version', width: 100 },
  ];

  return (
    <>
      <Layout
            active=''
            type="main"
            >
            <BlockContainer>
                <Container>
                    <Title type='emphasis' size='title' label='Google Wallet' align='center' color="main" />
                    <Title type='general' size='subtitle' variant='section' label="Modèles disponibles" color="main" />
                    {/* Begin */}
                    <center>Modèles actifs</center>
                    
                    <Align>
                      <ThemeProvider theme={themeWithLocale}>
                          <DataGrid columns={columns} 
                                    getRowId={(row) => row.classId}
                                    rows={dataGoogleClass} 
                                    rowSelection={false} 
                                    pageSizeOptions={[10,25,100]}
                                    initialState={{
                                      pagination: { 
                                        paginationModel: { pageSize: 10 },
                                      },
                                    }}
                                    slots={{ toolbar: CustomToolbar }}
                            />
                        </ThemeProvider>
                    </Align>
                    {/* End */}
                </Container>
            </BlockContainer>
        </Layout>   
    </>
  );
}

const BlockContainer = styledComponent.div`
  width: auto;
  position: relative;
  left: 20px;
  bottom: 50px;
`;


const Align = styledComponent.div`
p {
  margin-top: 13px
}
.MuiDataGrid-columnHeader{
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
}
.MuiButtonBase-root{
  color: ${THEME.background.color.general};
}
`;