import Layout from '../../../../containers/Layout'
import Title from '../../../../components/Text/Title'
import Container from '../../../../containers/Container'

import React, { useState, useEffect } from 'react'
import THEME from '../../../../styles/theme'
import { styled as styledComponent } from 'styled-components'
import Button from "@mui/material/Button"
import GoogleWalletAddCard from '../../../../assets/wallet/google/frFR_add_to_google_wallet_add-wallet-badge.png'
import endpoints from '../../../../mixins/endpoints'
import api from '../../../../mixins/api'
import apiBaseUrl from '../../../../mixins/apiBaseUrl'
import GoogleObject from '../../../../models/GoogleObject'
import LinkIcon from '@mui/icons-material/LinkRounded';
import { blue } from '@mui/material/colors'
import Paragraph from '../../../../components/Text/Paragraph';
import { DataGrid,  
  GridColDef, 
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector } from '@mui/x-data-grid';
  import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
  import * as locales from '@mui/material/locale';

export default function DisplayPass() {
  const [dataGoogleObject, setDataGoogleObject] = useState<GoogleObject[]>([]);
  const [dataGoogleObjectDisabled, setDataGoogleObjectDisabled] = useState<GoogleObject[]>([]);

  type SupportedLocales = keyof typeof locales;
  const [locale, setLocale] = React.useState<SupportedLocales>('frFR');
  const theme = useTheme();
  const themeWithLocale = React.useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme],
  );

  useEffect(() => {
    fetchGoogleObject()
    fetchGoogleObjectDisabled()
  }, []);

  const fetchGoogleObject = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework+ endpoints.data().GetAllGoogleObjectEndpoint + '?&filter=State eq true').then((response =>{
      setDataGoogleObject(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

  const fetchGoogleObjectDisabled = async () => {
    api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetAllGoogleObjectEndpoint + '?&filter=State eq false').then((response =>{
      setDataGoogleObjectDisabled(response.data)
    })).catch((error)=>{
        alert(error)
        console.log(error)
    })
  }

  function formatDate(parameter:string, hideHour : boolean){
    var result = ''
    if(parameter !== '')
    { 
      if(hideHour){
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'numeric', day: 'numeric' });
      }else{
        result = new Date(parameter).toLocaleDateString([],{ year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
      }
    }
    return result;
  }

  function styleDetail(style: number){
    if(style === 0){
      return "Générique"
    }
    // else if(style === 1){
    //   return "Billet d'évènement"
    // }
    // else if(style === 2){
    //   return "Carte d'embarquement"
    // }
    // else if(style === 3){
    //   return "Carte cadeau"
    // }
    // else if(style === 4){
    //   return "Carte de fidélité"
    // }
    // else if(style === 5){
    //   return "Carte de transport"
    // }
    else{
      return "Inconnu"
    }
  }
  
  function openNewTab(id: string) {
    let obj = dataGoogleObject.find(x => x.objectId === id)
    if(obj !== undefined && obj !== null){
      api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetGoogleObjectLinkEndpoint + "?classId=" + obj.classId +"&objectId=" + obj.objectId).then((response =>{
      window.open(response.data,"_blank");
      })).catch((error)=>{
          alert(error)
          console.log(error)
      })
    }
  }
  
  function getPassLink(id: string){
    let obj = dataGoogleObject.find(x => x.objectId === id)
    if(obj !== undefined && obj !== null){
      api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetGoogleObjectLinkEndpoint + "?classId=" + obj.classId +"&objectId=" + obj.objectId).then((response =>{
        navigator.clipboard.writeText(response.data)
      })).catch((error)=>{
          alert(error)
          console.log(error)
      })
    }
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }
  
  const columnsTab1: GridColDef[] = [
    { field: 'Id', headerName: 'Id', width: 180,
      valueGetter: ({id}) => {
        return id
      }
     },
    { field: 'style', headerName: 'Style', width: 100,
      renderCell: (param: GridRenderCellParams<any, number>)=>(
        styleDetail(param.value ? param.value : 0)
    )},
    { field: 'classId', headerName: 'Modèle', width: 200 },
    { field: 'title', headerName: 'Titre', width: 200 },
    { field: 'created', headerName: 'Créé', width: 140,
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '' , false)
    )},
    { field: 'lastUpdate', headerName: 'Mise à jour', width: 140, 
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '', false)
    )},
    { field: 'validityEnd', headerName: 'Expiration', width: 100,  
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '', true)
    )},
    { field: 'version', headerName: 'Version', width: 100 },
    { field: 'objectId', headerName: 'Actions', width: 240, headerAlign: 'center', align:'left',
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        <>
        <Button onClick={(e) =>{openNewTab(param.value ? param.value : '')}}>
          <img src={GoogleWalletAddCard} alt='' width={'125px'} />
        </Button>
        <Button onClick={(e) =>{getPassLink(param.value ? param.value : '')}}>
          <LinkIcon sx={{ fontSize: 40,color: blue[900] }} />
        </Button>
        </>
    )},
  ];

  const columnsTab2: GridColDef[] = [
    { field: 'objectId', headerName: 'Id', width: 200 },
    { field: 'style', headerName: 'Style', width: 100,
      renderCell: (param: GridRenderCellParams<any, number>)=>(
        styleDetail(param.value ? param.value : 0)
    )},
    { field: 'classId', headerName: 'Modèle', width: 200 },
    { field: 'title', headerName: 'Titre', width: 200 },
    { field: 'created', headerName: 'Créé', width: 140,
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '' , false)
    )},
    { field: 'lastUpdate', headerName: 'Mise à jour', width: 140, 
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '', false)
    )},
    { field: 'validityEnd', headerName: 'Expiration', width: 100,  
      renderCell: (param: GridRenderCellParams<any, string>)=>(
        formatDate(param.value ? param.value : '', true)
    )},
    { field: 'version', headerName: 'Version', width: 100 },
  ];

  return (
    <>
      <Layout
            active=''
            type="main"
            >
            <BlockContainer>
                <Container>
                    <Title type='emphasis' size='title' label='Google Wallet' align='center' color="main" />
                    <Title type='general' size='subtitle' variant='section' label="Cartes disponibles" color="main" />
                    {/* Begin */}
                    <center>CARTES ACTIVES</center>
                    <Paragraph type='general' size='subparagraph' color="main">            
                      <LinkIcon sx={{ fontSize: 20,color: blue[900] }}/> Copier le lien
                    </Paragraph>
                    <Align>
                        <ThemeProvider theme={themeWithLocale}>
                          <DataGrid columns={columnsTab1} 
                                    getRowId={(row) => row.objectId}
                                    rows={dataGoogleObject} 
                                    rowSelection={false} 
                                    pageSizeOptions={[10,25,100]}
                                    initialState={{
                                      pagination: { 
                                        paginationModel: { pageSize: 10 },
                                      },
                                    }}
                                    slots={{ toolbar: CustomToolbar }}
                            />
                        </ThemeProvider>
                    </Align>
                    <br /><br />
                    <center>CARTES INACTIVES</center>
                    
                    <Align>
                        <ThemeProvider theme={themeWithLocale}>
                          <DataGrid columns={columnsTab2} 
                                    getRowId={(row) => row.objectId}
                                    rows={dataGoogleObjectDisabled} 
                                    rowSelection={false} 
                                    pageSizeOptions={[10,25,100]}
                                    initialState={{
                                      pagination: { 
                                        paginationModel: { pageSize: 10 },
                                      },
                                    }}
                                    slots={{ toolbar: CustomToolbar }}
                            />
                        </ThemeProvider>
                    </Align>
                    {/* End */}
                </Container>
            </BlockContainer>
        </Layout>  
    </>
  );
}

const BlockContainer = styledComponent.div`
  width: auto;
  position: relative;
  left: 20px;
  bottom: 50px;
`;

const Align = styledComponent.div`
p {
  margin-top: 13px
}
.MuiDataGrid-columnHeader{
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
}
.MuiButtonBase-root{
  color: ${THEME.background.color.general};
}
`;