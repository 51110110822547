import { ReactNode } from 'react';
import { styled } from "@mui/material/styles";
import { styled as styledComponent } from 'styled-components';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button";
import THEME from '../../../styles/theme';
import Paper from '@mui/material/Paper';

interface IModalProps {
  children: ReactNode
  display: boolean
  setDisplay: (display: boolean) => void
  type?: string
  position?: string
  title?: string
  text?: string
}

function DialogComponent(props: IModalProps) {

  const handleClose = () => {
    props.setDisplay && props.setDisplay(false);
  };

  return (
    <Dialog
      open={props.display} 
      onClose={handleClose}
      PaperComponent={PaperStyle}
    >
      {/* <DialogTitle>{props.title}</DialogTitle> */}
      <DialogContent>
        <ContentContainer >
            {props.text}
        </ContentContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} 
         sx={[{fontWeight:'bold',backgroundColor: '#1B365F', color:'#ffffff'},
          {'&:hover': {fontWeight:'bold', backgroundColor:'#ffffff', color:'#1B365F'}} 
         ]}
         autoFocus>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogComponent;

const PaperStyle = styled(Paper)`
  background-color: ${THEME.background.color.general};
  color: ${THEME.text.color.general};
`;

const ContentContainer = styledComponent.div`
  color: ${THEME.text.color.general};
`;
