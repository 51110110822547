import { styled } from 'styled-components'
import Layout from '../../../containers/Layout'
import Title from '../../../components/Text/Title'
import Container from '../../../containers/Container'

import { useState, useEffect } from 'react';
import Form from '../../../components/Forms/FormV2';
import { IInputProps } from '../../../components/Forms/InputV2';
import endpoints from '../../../mixins/endpoints';
import api from '../../../mixins/api';
import apiBaseUrl from '../../../mixins/apiBaseUrl';
import Dialog from '../../../components/Modals/Dialog';
import DialogImg from '../../../components/Modals/DialogImg';
import Logo from '../../../models/Logo'
import Button from "@mui/material/Button";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { useTheme } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { blue, grey } from '@mui/material/colors';
import SendIcon from '@mui/icons-material/Send';

function BusinessCard() {
    const rx = /^(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])$/
    const [dataLogos, setDataLogos] = useState<Logo[]>([]);
    const [selectedLogo, setSelectedLogo] = useState("")
    const [displayDialog, setDisplayDialog] = useState(false)
    const [dialogMessage, setDialogMessage] = useState("")
    const [displayDialogImg, setDisplayDialogImg] = useState(false) 
    const [imgDialog, setimgDialog] = useState("")

    const [firstname, setfirstname] = useState("")
    const [lastname, setlastname] = useState("")
    const [email, setemail] = useState("")
    const [mobilephone, setmobilephone] = useState("")
    const [homephone, sethomephone] = useState("")
    const [workphone, setworkphone] = useState("")
    const [homefax, sethomefax] = useState("")
    const [workfax, setworkfax] = useState("")
    const [address, setaddress] = useState("")
    const [city, setcity] = useState("")
    const [postalcode, setpostalcode] = useState("")
    const [state, setstate] = useState("")
    const [country, setcountry] = useState("")
    const [organization, setorganization] = useState("")
    const [jobtitle, setjobtitle] = useState("")
    const [birthday, setbirthday] = useState("")
    const [website, setwebsite] = useState("")
    const [note, setnote] = useState("")
    const [qrCodeColor, setQrCodeColor] = useState("")
    const [qrCodeName, setQrCodeName] = useState("")
    const [qrCodeStyle, setQrCodeStyle] = useState("0")

    const [qrCodeSize, setQrCodeSize] = useState("")
    const [selectedSize, setSelectedSize] = useState("")
    const [selectedStyle, setSelectedStyle] = useState("Standard")
    const [customSize, setCustomSize] = useState(false)

    const [record, setRecord] = useState(false)
    
    const QRRECORDED: IInputProps[] = [
        {
            input:'text',
            type:'full',
            required: true,
            id:'name',
            label:'Nom du QR Code',
            name:'name',
            placeholder:'Veuillez saisir un nom',
            value: qrCodeName,
            setValue: setQrCodeName
        }
    ]
    const QRVCODE: IInputProps[] = [{
        input:'text',
        type:'full',
        required: true,
        id:'lastname',
        label:'Nom',
        labelPosition: 'left',
        name:'lastname',
        placeholder:'Nom',
        value: lastname,
        setValue: setlastname
    },
    {
        input:'text',
        type:'full',
        id:'address',
        label:'Adresse',
        labelPosition: 'left',
        name:'address',
        placeholder:'Adresse',
        value: address,
        setValue: setaddress
    },
    {
        input:'text',
        type:'full',
        id:'postalcode',
        label:'Code postale',
        labelPosition: 'left',
        name:'postalcode',
        placeholder:'Code postale',
        value: postalcode,
        setValue: setpostalcode
    },
    {
        input:'email',
        type:'full',
        required: true,
        id:'email',
        label:'Adresse mail',
        labelPosition: 'left',
        name:'email',
        placeholder:'Adresse mail',
        value: email,
        setValue: setemail
    },
    {
        input:'text',
        type:'full',
        id:'workfax',
        label:'Fax bureau',
        labelPosition: 'left',
        name:'workfax',
        placeholder:'Fax bureau',
        value: workfax,
        setValue: setworkfax
    },
    {
        input:'text',
        type:'full',
        id:'homefax',
        label:'Fax domicile',
        labelPosition: 'left',
        name:'homefax',
        placeholder:'Fax domicile',
        value: homefax,
        setValue: sethomefax
    },
    {
        input:'text',
        type:'full',
        id:'organization',
        label:'Société',
        labelPosition: 'left',
        name:'organization',
        placeholder:'Nom de la société',
        value: organization,
        setValue: setorganization
    },
    {
        input:'text',
        type:'full',
        id:'jobtile',
        label:'Fonction',
        labelPosition: 'left',
        name:'jobtitle',
        placeholder:'Intitulé du poste',
        value: jobtitle,
        setValue: setjobtitle
    },
    {
        input:'text',
        type:'full',
        id:'website',
        label:'Site web',
        labelPosition: 'left',
        name:'website',
        placeholder:'Url du site web',
        value: website,
        setValue: setwebsite
    }
    ]
    const QRVCODE_SUITE: IInputProps[] = [{
        input:'text',
        type:'full',
        required: true,
        id:'firstname',
        label:'Prénom',
        labelPosition: 'left',
        name:'firstname',
        placeholder:'Prénom',
        value: firstname,
        setValue: setfirstname
    },
    {
        input:'text',
        type:'full',
        id:'city',
        label:'Ville',
        labelPosition: 'left',
        name:'city',
        placeholder:'Ville',
        value: city,
        setValue: setcity
    },
    {
        input:'text',
        type:'full',
        id:'country',
        label:'Pays',
        labelPosition: 'left',
        name:'country',
        placeholder:'Pays',
        value: country,
        setValue: setcountry
    },
    {
        input:'text',
        type:'full',
        id:'state',
        label:'Etat',
        labelPosition: 'left',
        name:'state',
        placeholder:'Etat',
        value: state,
        setValue: setstate
    },
    {
        input:'text',
        type:'full',
        id:'workphone',
        label:'Téléphone bureau',
        labelPosition: 'left',
        name:'workphone',
        placeholder:'Téléphone bureau',
        value: workphone,
        setValue: setworkphone
    },
    {
        input:'text',
        type:'full',
        required: true,
        id:'mobilephone',
        label:'Téléphone portable',
        labelPosition: 'left',
        name:'mobilephone',
        placeholder:'Téléphone portable',
        value: mobilephone,
        setValue: setmobilephone
    },
    {
        input:'text',
        type:'full',
        id:'homephone',
        label:'Téléphone domicile',
        labelPosition: 'left',
        name:'homephone',
        placeholder:'Téléphone domicile',
        value: homephone,
        setValue: sethomephone
    },
    {
        input:'text',
        type:'full',
        id:'birthday',
        label:'Date anniversaire',
        labelPosition: 'left',
        name:'birthday',
        placeholder:'Date anniversaire',
        value: birthday,
        setValue: setbirthday
    },
    {
        input:'text',
        type:'full',
        id:'note',
        label:'Note',
        labelPosition: 'left',
        name:'note',
        placeholder:'Note',
        value: note,
        setValue: setnote
    }
    ]

    function setColor(params : string){
        setQrCodeColor(params)
    }

    const fetchLogos = async () => {
        api.methods.get(apiBaseUrl.data().digitalFramework + endpoints.data().GetAllLogoEndpoint + "?orderby=name").then((response =>{
          setDataLogos(response.data)
            })).catch((error)=>{
                alert(error)
                console.log(error)
            })
        }
        
        useEffect(() => {
          fetchLogos();
        }, []);

    const updateSelectedLogo = (event: SelectChangeEvent) => {
        let value = event.target.value as string
        setSelectedLogo(value);
        setQrCodeColor(dataLogos.find((item)=> item.name === value)?.backgroundColor ?? "")
      };

    const updateSelectedSize = (event: SelectChangeEvent) => {
        let value = event.target.value as string
        if(value === "custom"){
            setCustomSize(true)
            setSelectedSize(value);
            setQrCodeSize(" ")
            
        }else{
            setCustomSize(false)
            setSelectedSize(value);
            setQrCodeSize(value)
        }
      };

    function setSize(params : string){
        setQrCodeSize(params)
    }

    const updateSelectedStyle = (event: SelectChangeEvent) => {
        let value = event.target.value as string
        let result
        switch(value){
            case "Standard":
                result = '0'
                break
            case "Squares":
                result = '1'
                break
            case "Dots":
                result = '2'
                break
            default: 
                result = '0'
                break
        }
        setSelectedStyle(value)
        setQrCodeStyle(result)
    }

    function updateRecordCheckbox(){
        if(!record){
            setRecord(true)
        }
        else{
            setRecord(false)
        }
    }

    function checkInputs(){
        if(qrCodeSize.trim() === ""){
            setDialogMessage("Veuillez renseigner une résolution")
            setDisplayDialog(true)
        }
        else if(selectedLogo === ""){
            setDisplayDialog(true)
            setDialogMessage("Veuillez sélectionner une entité")
        }
        else if(firstname.trim() === '' || lastname.trim() === '' || email.trim() === '' || mobilephone.trim() === ''){
            setDisplayDialog(true)
            setDialogMessage("Veuillez renseigner les champs requis")
        }
        else if(qrCodeColor !== '' && !rx.test(qrCodeColor)){
            setDisplayDialog(true)
            setDialogMessage("Veuillez renseigner une couleur composée de trois valeurs allant de 0 à 255 séparées par une virgule (exemple: 50,45,255)")
        }
        else if(record && qrCodeName.trim() === ''){
            setDisplayDialog(true)
            setDialogMessage("Veuillez renseigner un nom pour le QR Code")
        }
        else{
            QrvCodeRequest()
        }
    }

    function QrvCodeRequest(){
        let queryString;
        queryString = apiBaseUrl.data().digitalFramework + endpoints.data().BuildQrvCodeEndpoint;
        queryString = queryString  + "?logoName=" + selectedLogo + "&qrCodeSize=" + qrCodeSize.trim() + "&color=" + qrCodeColor + "&firstName=" + firstname + "&lastName=" + lastname + "&email=" + email + "&mobilePhone=" + mobilephone + "&style=" + qrCodeStyle
        if(workphone.trim() !== ''){
            queryString = queryString  + "&workPhone=" + workphone
        }
        if(homephone.trim() !== ''){
            queryString = queryString  + "&homePhone=" + homephone
        }
        if(workfax.trim() !== ''){
            queryString = queryString  + "&workFax=" + workfax
        }
        if(homefax.trim() !== ''){
            queryString = queryString  + "&homeFax=" + homefax
        }
        if(address.trim() !== ''){
            queryString = queryString  + "&address=" + address
        }
        if(city.trim() !== ''){
            queryString = queryString  + "&city=" + city
        }
        if(postalcode.trim() !== ''){
            queryString = queryString  + "&postalCode=" + postalcode
        }
        if(country.trim() !== ''){
            queryString = queryString  + "&country=" + country
        }
        if(state.trim() !== ''){
            queryString = queryString  + "&state=" + state
        }
        if(organization.trim() !== ''){
            queryString = queryString  + "&organization=" + organization
        }
        if(jobtitle.trim() !== ''){
            queryString = queryString  + "&jobTitle=" + jobtitle
        }
        if(birthday.trim() !== ''){
            queryString = queryString  + "&birthday=" + birthday
        }
        if(website.trim() !== ''){
            queryString = queryString  + "&webSite=" + website
        }
        if(note.trim() !== ''){
            queryString = queryString  + "&note=" + note
        }
        if(record){
            queryString = queryString  + "&record=" + record
            queryString = queryString  + "&recordName=" + qrCodeName
        }

        api.methods.get(queryString)
        .then((response =>{
                setimgDialog(response.data.result.image)
                setDisplayDialogImg(true)
    
        })).catch((error)=>{
            console.log(error)
            setDialogMessage("Un QR Code portant le même nom existe déjà")
            setDisplayDialog(true)
        })
    }

    return (
        <>
        <Layout
            active=''
            type="main"
            >
            <BlockContainer>
                <Container>
                    <Title type='emphasis' size='title' label='QRCode' align='center' color="main" />
                    <Title type='general' size='subtitle' variant='section' label='Carte de visite' color="main" />
                    {/* Begin */}
                    <Dialog type='main' display={displayDialog} setDisplay={setDisplayDialog} title='AGL - QRV Code' text={dialogMessage}>customdialog</Dialog>
                    <DialogImg type='main' display={displayDialogImg} setDisplay={setDisplayDialogImg} title='AGL - QRV Code' imagebase64={imgDialog} copyEnabled={true}>customdialog</DialogImg>
                    <center>
                    <FormControlLabel control={<Checkbox sx={{color: grey[600],'&.Mui-checked': {color: blue[900]}}} checked={record} onChange={updateRecordCheckbox} />} label="Enregistrer" /><br/>
                    <FormControl sx={{ m: 1, width:'20ch' }} variant="standard">
                    <InputLabel id="qrcodesize-label">Taille</InputLabel>
                        <Select
                            labelId="qrcodesize-label"
                            id="qrcodesize-select"
                            value={selectedSize}
                            label="Taille"
                            onChange={updateSelectedSize}
                        >
                            <MenuItem key="1" value="350">Web</MenuItem>
                            <MenuItem key="2" value="720">SD</MenuItem>
                            <MenuItem key="3" value="1280">HD</MenuItem>
                            <MenuItem key="4" value="1920">FHD</MenuItem>
                            <MenuItem key="5" value="3840">4K</MenuItem>
                            <MenuItem key="6" value="custom">Personnalisée</MenuItem>
                        </Select>
                    </FormControl>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControl sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="textbox-qrcodesize">Résolution</InputLabel>
                            <BootstrapInput id="textbox-qrcodesize" readOnly={!customSize} value={qrCodeSize} onChange={(e)=>setSize(e.target.value)}/>
                        </FormControl>
                        <br/>
                        <FormControl sx={{ m: 1, width:'20ch' }} variant="standard">
                            <InputLabel id="logos-label">Entité</InputLabel>
                            <Select
                                labelId="logos-label"
                                id="logos-select"
                                value={selectedLogo}
                                label="Entité"
                                onChange={updateSelectedLogo}
                            >
                                {dataLogos.map((item)=><MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <FormControl sx={{ m: 1 }} variant="standard">
                            <InputLabel htmlFor="demo-customized-textbox">Couleur RGB</InputLabel>
                            <BootstrapInput id="demo-customized-textbox" value={qrCodeColor} onChange={(e)=>setColor(e.target.value)}/>
                        </FormControl>
                        <br/>
                        <FormControl sx={{ m: 1, width:'20ch' }} variant="standard">
                            <InputLabel id="qrcodestyle-label">Style</InputLabel>
                            <Select
                                labelId="qrcodestyle-label"
                                id="qrcodestyle-select"
                                value={selectedStyle}
                                label="Style"
                                onChange={updateSelectedStyle}
                            >
                                <MenuItem key="1" value="Standard">Standard</MenuItem>
                                <MenuItem key="2" value="Squares">Squares</MenuItem>
                                <MenuItem key="3" value="Dots">Dots</MenuItem>
                            </Select>
                        </FormControl>
                    </center>
                    {
                        record ? 
                        <Form
                        // logo='main'
                        title=""
                        first={QRRECORDED}
                        type="main"
                        form="half"
                        design="transparent"
                        corporate
                        ></Form>
                        : null
                    }
                    
                    <Form
                        // logo="main"
                        title=""
                        first={QRVCODE}
                        second={QRVCODE_SUITE}
                        type="main"
                        form="full" 
                        design="transparent"
                        corporate
                    >
                        <br/>
                        <Button variant="outlined" endIcon={<SendIcon />} onClick={checkInputs} sx={{color:'#1B365F'}}>Générer</Button>
                    </Form>
         {/* End */}
         </Container>
            </BlockContainer>
        </Layout>    
        </>
    );
}

export default BusinessCard;

const BlockContainer = styled.div`
  width: 150vh;
  position: relative;
  left: 20px;
  bottom: 50px;
`;

const BootstrapInput = styled(InputBase)(() => ({
    'label + &': {
      marginTop: useTheme().spacing(2),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      width: '100px',
      textAlign: 'center',
      height:'10px',
      position: 'relative',
      backgroundColor: useTheme().palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: useTheme().transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));